<template>
    <div>
        <Header title="Condomínios" :hasAddBtn="false"/>

        <b-table sticky-header="65vh" no-border-collapse hover :items="itemsFiltered" :fields="fields" :busy="busy">
            <template #cell(actions)="row">
                <div class="btn-icons">
                    <div><i @click="showFiles(row.item)" class="material-icons btn-icon">source</i><span class="btn-text">Ver Arquivos</span></div>
                    <div><i @click="sendFiles(row.item)" class="material-icons btn-icon">post_add</i><span class="btn-text">Adicionar Arquivos</span></div>
                    <div><i @click="editEvents(row.item)" class="material-icons btn-icon">edit_calendar</i><span class="btn-text">Editar Eventos</span></div>
                    <div><i @click="deleteFiles(row.item)" class="material-icons btn-icon">delete</i><span class="btn-text">Deletar Arquivos</span></div>
                </div>
            </template>
            <template #table-busy>
              <div class="text-center my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong class="pl-2">Carregando...</strong>
              </div>
            </template>
            <template #head()="key">
              <div class="d-flex flex-row align-items-center">
                <div>{{ key.label }}</div>
                <template v-if="key.field.key != 'actions'">
                  <input
                    type="text"
                    class="form-control mx-4"
                    :id="'filter-' + key.field.key"
                    v-model="filterData[key.field.key]"
                  />
                </template>
              </div>
            </template>
        </b-table>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          size="md"
          align="right"
          class="my-0"
        ></b-pagination>

      <b-modal id="send-files" hide-footer>
        <template #modal-title>Adicionar Arquivos - {{ itemSelected.Nome_Condominio }}</template>
        <b-form ref="sendFiles" @submit.prevent="sendFilesSubmit">
          <b-form-file
            v-model="files"
            :state="Boolean(files)"
            placeholder="Clique ou arraste um ou mais arquivos"
            drop-placeholder="Clique ou arraste um ou mais arquivos"
            multiple
            required
          ></b-form-file>

          <div class="d-flex flex-column justify-content-center text-center mt-2">
            <b-spinner v-show="loading" variant="primary" label="Spinning"></b-spinner>

            <div>
              <b-button class="float-right" variant="outline-primary" type="submit">Enviar</b-button>
            </div>
          </div>
        </b-form>
      </b-modal>

      <b-modal id="get-files" hide-footer>
        <template #modal-title>Arquivos - {{ itemSelected.Nome_Condominio }}</template>
        <div class="d-flex flex-column justify-content-center align-items-center text-center my-1">
            <b-spinner v-if="loading && !filesList.length" variant="primary" label="Spinning"></b-spinner>
            <div class="d-flex flex-column" v-else>
              <template v-if="filesList.length">
                <div v-for="f in filesList" :key="f">
                  <a :href="f" target="_blank">{{ last(f) }}</a>
                </div>
              </template>
              <template v-else>
                <div>Sem arquivos</div>
              </template>
            </div>
        </div>
      </b-modal>

      <b-modal id="delete-files" hide-footer>
        <template #modal-title>Deletar Arquivos - {{ itemSelected.Nome_Condominio }}</template>
          <div class="d-flex flex-column justify-content-center align-items-center text-center my-1">
            <b-spinner v-if="loading && !filesList.length" variant="primary" label="Spinning"></b-spinner>
            <div class="d-flex flex-column w-100" v-else>
              <template v-if="filesList.length">
                <div class="pb-2 d-flex flex-row align-items-center justify-content-between" v-for="f in filesList" :key="f">
                  <a :href="f" target="_blank">{{ last(f) }}</a>
                  <i @click="deleteFileSubmit(last(f))" class="material-icons btn-icon">delete</i>
                </div>
              </template>
              <template v-else>
                <div>Sem arquivos</div>
              </template>
            </div>
        </div>
      </b-modal>

      <b-modal id="edit-events" hide-footer>
        <template #modal-title>Eventos</template>
        <div class="d-flex flex-column justify-content-center align-items-center text-center my-1">
            <b-spinner v-if="loading" variant="primary" label="Spinning"></b-spinner>
            <div class="d-flex flex-column" v-else>
              <div class="row">

                <div class="col-6">
                  <b-form-group label="Custas" label-for="custas" style="text-align: left;">
                    <b-form-select id="custas" v-model="formEvent.custas" :options="events" class="mb-3">
                      <b-form-select-option :value="undefined" disabled>Sem Evento</b-form-select-option>
                    </b-form-select>
                  </b-form-group>
                </div>

                <div class="col-6">
                  <b-form-group label="Protesto" label-for="protesto" style="text-align: left;">
                    <b-form-select id="protesto" v-model="formEvent.protesto" :options="events" class="mb-3">
                      <b-form-select-option :value="undefined" disabled>Sem Evento</b-form-select-option>
                    </b-form-select>
                  </b-form-group>
                </div>

                <div class="col-6">
                  <b-form-group label="Despesa Honorário" label-for="desp_honor" style="text-align: left;">
                    <b-form-select id="desp_honor" v-model="formEvent.desp_honor" :options="events" class="mb-3">
                      <b-form-select-option :value="undefined" disabled>Sem Evento</b-form-select-option>
                    </b-form-select>
                  </b-form-group>
                </div>

                <div class="col-6">
                  <b-form-group label="Tarifa Baixa" label-for="tarifa_baixa" style="text-align: left;">
                    <b-form-select id="tarifa_baixa" v-model="formEvent.tarifa_baixa" :options="events" class="mb-3">
                      <b-form-select-option :value="undefined" disabled>Sem Evento</b-form-select-option>
                    </b-form-select>
                  </b-form-group>
                </div>

                <div class="col-6">
                  <b-form-group label="Tarifa Registro" label-for="tarifa_registro" style="text-align: left;">
                    <b-form-select id="tarifa_registro" v-model="formEvent.tarifa_registro" :options="events" class="mb-3">
                      <b-form-select-option :value="undefined" disabled>Sem Evento</b-form-select-option>
                    </b-form-select>
                  </b-form-group>
                </div>

                <div class="col-6">
                  <b-form-group label="Multa" label-for="multa" style="text-align: left;">
                    <b-form-select id="multa" v-model="formEvent.multa" :options="events" class="mb-3">
                      <b-form-select-option :value="undefined" disabled>Sem Evento</b-form-select-option>
                    </b-form-select>
                  </b-form-group>
                </div>

                <div class="col-6">
                  <b-form-group label="Juros" label-for="juros" style="text-align: left;">
                    <b-form-select id="juros" v-model="formEvent.juros" :options="events" class="mb-3">
                      <b-form-select-option :value="undefined" disabled>Sem Evento</b-form-select-option>
                    </b-form-select>
                  </b-form-group>
                </div>

                <div class="col-6">
                  <b-form-group label="Correção monetária" label-for="correcao_monetaria" style="text-align: left;">
                    <b-form-select id="correcao_monetaria" v-model="formEvent.correcao_monetaria" :options="events" class="mb-3">
                      <b-form-select-option :value="undefined" disabled>Sem Evento</b-form-select-option>
                    </b-form-select>
                  </b-form-group>
                </div>

              </div>

              <div>
                <b-button @click="submitEvents" class="float-right" variant="primary" type="submit">Salvar</b-button>
              </div>

            </div>
        </div>
      </b-modal>

    </div>
</template>

<style>

table.b-table[aria-busy='true'] {
  opacity: 1 !important;
}

</style>

<script>

import Header from '@/components/Dashboard/Header'

import api from '@/services/condinvest'
import { toast } from '@/utils'

export default {
  components: {
    Header
  },
  created () {
    api.get('condominio/index').then((response) => {
      console.log(response.data)
      if (response.status === 200) {
        this.condominios = response.data
        this.totalRows = this.condominios.length
        this.busy = false
      }
    }, error => {
      toast(this, 'danger', 'Erro ao obter condomínios', error.message)
      this.busy = false
      return Promise.reject(error)
    })
  },
  computed: {
    itemsFiltered () {
      const z = ['Id_Condominio', 'Nome_Condominio']
      return this.condominios.filter(a => z.reduce((r, f) => r && a[f].toLowerCase().includes(this.filterData[f]?.toLowerCase() || ''), 1))
    }
  },
  watch: {
    itemsFiltered (v) {
      this.totalRows = v.length
    }
  },
  data: () => ({
    isFiltering: true,
    condominios: [],
    busy: true,
    filterData: {},
    totalRows: 1,
    currentPage: 1,
    perPage: 50,
    debounce: false,
    debounceTime: 500, // ms
    itemSelected: null,
    files: null,
    filesList: [],
    loading: false,
    last: a => a.split('/')[a.split('/').length - 1],
    events: [],
    formEvent: {
      custas: undefined,
      protesto: undefined,
      desp_honor: undefined,
      tarifa_baixa: undefined,
      tarifa_registro: undefined,
      multa: undefined,
      juros: undefined,
      correcao_monetaria: undefined
    },
    eventId: null,
    meta: {
      title: 'User',
      module: 'users',
      state: 'users',
      modes: {
        edit: {
          title: 'Editar Usuário',
          success: 'Usuário editado com successo',
          urn: 'auth/edit',
          response: 'user',
          method: 'post'
        },
        attachToGroup: {
          title: 'Adicionar Usuário a um Grupo',
          success: 'Usuário linkado com sucesso ao grupo',
          urn: 'group/linkuser',
          response: 'group',
          method: 'post'
        }
      }
    },
    fields: [
      { key: 'Id_Condominio', label: 'ID Condomínio', sortable: true },
      { key: 'Nome_Condominio', label: 'Nome Condomínio', sortable: true },
      { key: 'actions', label: 'Ações' }
    ]
  }),
  methods: {
    sendFiles (item) {
      this.itemSelected = item
      this.$bvModal.show('send-files')
    },
    sendFilesSubmit () {
      if (this.loading) return

      if (!this.$refs.sendFiles.checkValidity()) {
        toast(this, 'info', 'Formulário incompleto', 'Preencha os campos corretamente')
        return
      }
      const form = new FormData()

      form.append('id', this.itemSelected.Id_Condominio)

      this.files.forEach((file, index) => {
        form.append('files[' + index + ']', file)
      })

      this.loading = true

      api.post('condominio/saveFiles', form).then(
        response => {
          this.$bvModal.hide('send-files')
          toast(this, 'success', 'Sucesso', 'Arquivos salvos com sucesso')
          this.loading = false
        },
        error => {
          toast(this, 'danger', 'Erro', 'Erro ao enviar arquivos')
          this.loading = false
          return Promise.reject(error)
        },
        true // multipart
      )
    },
    showFiles (item) {
      this.itemSelected = item
      this.$bvModal.show('get-files')
      this.filesList = []
      this.loading = true
      api.post('condominio/indexFiles', { id: item.Id_Condominio }).then(
        response => {
          this.filesList = response.data
          this.loading = false
        },
        error => {
          toast(this, 'danger', 'Erro', 'Erro ao recuperar arquivos')
          this.loading = false
          return Promise.reject(error)
        }
      )
    },
    deleteFiles (item) {
      this.itemSelected = item
      this.$bvModal.show('delete-files')
      this.filesList = []
      this.loading = true
      api.post('condominio/indexFiles', { id: item.Id_Condominio }).then(
        response => {
          this.filesList = response.data
          this.loading = false
        },
        error => {
          toast(this, 'danger', 'Erro', 'Erro ao recuperar arquivos')
          this.loading = false
          return Promise.reject(error)
        }
      )
    },
    deleteFileSubmit (file) {
      if (!confirm('Tem certeza que deseja deletar esse arquivo?')) {
        return
      }
      api.post('condominio/removeFiles', { id: this.itemSelected.Id_Condominio, file: file }).then(
        response => {
          toast(this, 'success', 'Sucesso', 'Arquivo deletado com sucesso')
          this.filesList = this.filesList.filter(a => this.last(a) !== file)
          this.loading = false
        },
        error => {
          toast(this, 'danger', 'Erro', 'Erro ao deletar arquivo')
          this.loading = false
          return Promise.reject(error)
        }
      )
    },
    editEvents (item) {
      this.$bvModal.show('edit-events')
      this.loading = true
      this.eventId = item.Id_Condominio
      api.get('object/getEvents?condominio=' + item.Id_Condominio).then(
        response => {
          this.events = (response.data || []).map(e => ({ value: e.Codigo_Evento, text: e.Descricao_Evento, cat: e.CategoriaEvento }))

          this.formEvent.custas = this.events.find(e => e.cat === 'custas')?.value || undefined
          this.formEvent.protesto = this.events.find(e => e.cat === 'protesto')?.value || undefined
          this.formEvent.desp_honor = this.events.find(e => e.cat === 'desp_honor')?.value || undefined
          this.formEvent.tarifa_baixa = this.events.find(e => e.cat === 'tarifa_baixa')?.value || undefined
          this.formEvent.tarifa_registro = this.events.find(e => e.cat === 'tarifa_registro')?.value || undefined
          this.formEvent.multa = this.events.find(e => e.cat === 'multa')?.value || undefined
          this.formEvent.juros = this.events.find(e => e.cat === 'juros')?.value || undefined
          this.formEvent.correcao_monetaria = this.events.find(e => e.cat === 'correcao_monetaria')?.value || undefined

          this.formEvent = { ...this.formEvent }

          this.loading = false
        },
        error => {
          toast(this, 'danger', 'Erro', 'Erro ao recuperar eventos')
          this.loading = false
          return Promise.reject(error)
        }
      )
    },
    submitEvents () {
      api.post('object/updateEvent', {
        condominio: this.eventId,
        categorias: [
          { key: 'custas', value: this.formEvent.custas },
          { key: 'protesto', value: this.formEvent.protesto },
          { key: 'desp_honor', value: this.formEvent.desp_honor },
          { key: 'tarifa_baixa', value: this.formEvent.tarifa_baixa },
          { key: 'tarifa_registro', value: this.formEvent.tarifa_registro },
          { key: 'multa', value: this.formEvent.multa },
          { key: 'juros', value: this.formEvent.juros },
          { key: 'correcao_monetaria', value: this.formEvent.correcao_monetaria }
        ].filter(e => !!e.value)
      }).then(
        response => {
          this.$bvModal.hide('edit-events')
          toast(this, 'success', 'Sucesso', 'Eventos atualizados com sucesso')
        },
        error => {
          toast(this, 'danger', 'Erro', 'Erro ao salvar eventos')
          return Promise.reject(error)
        }
      )
    }
  }
}
</script>
